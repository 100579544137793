import React from "react"
import { Button, ButtonGroup, Icon } from "@shopify/polaris"
import { ArrowLeftMinor, ArrowRightMinor } from "@shopify/polaris-icons"

export const PaymentPlanPagination = props => {
  const { showPrev, showNext, onPagination } = props

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ButtonGroup connectedTop={false} segmented={true}>
        <Button onClick={() => onPagination(true, false)} disabled={!showPrev}>
          <Icon source={ArrowLeftMinor} />
        </Button>
        <Button onClick={() => onPagination(false, true)} disabled={!showNext}>
          <Icon source={ArrowRightMinor} />
        </Button>
      </ButtonGroup>
    </div>
  )
}
