import React, { useState } from "react"
import { Card, ResourceList, Tabs } from "@shopify/polaris"

import { PaymentPlanItem } from "./PaymentPlanItem"
import { PaymentPlansFilter } from "./PaymentPlansFilter"

export const PaymentPlanList = props => {
  const { items, status, setStatus, orderAmount, setOrderAmount, onClickPlan, queryValue, onQueryChange, loading } = props

  const [selectedItems, setSelectedItems] = useState([])

  const resourceName = {
    singular: "plan",
    plural: "plans",
  }
  const tabs = [
    {
      id: "all-plans",
      content: "All",
      accessibilityLabel: "All plans",
    },
    {
      id: "active-plans",
      content: "Active",
      accessibilityLabel: "Active plans",
      statusID: "Active",
    },
    {
      id: "completed",
      content: "Completed",
      accessibilityLabel: "Completed plans",
      statusID: "Expired",
    },
    {
      id: "cancelled-plans",
      content: "Cancelled",
      statusID: "Canceled",
    },
  ]

  const currentTabIndex = status && status.length ? tabs.map(t => t.statusID).indexOf(status[0]) : 0

  const buildFilterControl = () => {
    return (
      <PaymentPlansFilter
        status={status}
        orderAmount={orderAmount}
        queryValue={queryValue}
        setStatus={setStatus}
        setOrderAmount={setOrderAmount}
        setQueryValue={onQueryChange}
      />
    )
  }

  const handleTabChange = selectedTabIndex => {
    const tab = tabs[selectedTabIndex]
    setStatus(tab.statusID ? [tab.statusID] : [])
  }

  return (
    <Card>
      <Tabs tabs={tabs} selected={currentTabIndex} onSelect={handleTabChange}></Tabs>
      <ResourceList
        loading={loading}
        showHeader
        resourceName={resourceName}
        items={items}
        renderItem={renderItem}
        selectedItems={selectedItems}
        onSelectionChange={setSelectedItems}
        filterControl={buildFilterControl()}
      />
    </Card>
  )

  function renderItem(item) {
    return <PaymentPlanItem item={item} onClickPaymentPlan={onClickPlan} />
  }
}
