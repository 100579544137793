import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { RoutePropagator, TitleBar } from "@shopify/app-bridge-react"
import { Page } from "@shopify/polaris"
import { ReactifyApp } from "@reactify-apps/reactify-core-app"

import { reverseShopifyAdminId } from "../../helpers/utils"

import { genericNavBar } from "../../helpers/navigation"
import { PaymentPlan } from "components/Payment/PaymentPlan"

const ManagePaymentPlanPage = props => {
  const { firebase, shop, location } = props

  const pageSize = 10
  const searchParams = new URLSearchParams(window.location.search)

  const [items, setItems] = useState([])
  const [initialDoc, setInitialDoc] = useState(false)
  const [loading, setLoading] = useState(true)
  const [firstDoc, setFirstDoc] = useState(searchParams.get("first") ? atob(searchParams.get("first")) : false)
  const [lastDoc, setLastDoc] = useState(searchParams.get("last") ? atob(searchParams.get("last")) : false)
  const [showPrev, setPrev] = useState(false)
  const [showNext, setNext] = useState(true)
  const [queryValue, setQueryValue] = useState(searchParams.get("search") || false)
  const [selectedItems, setSelectedItems] = useState([])
  const [status, setStatus] = useState([])
  const [orderAmount, setOrderAmount] = useState([])

  const toTitleCase = str => {
    return str.replace(/\w\S*/g, txt => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }

  const handleData = (snapshot, initial = false) => {
    const length = snapshot.docs.length

    setItems(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })))
    if (length) {
      if (initial) setInitialDoc(snapshot.docs[0].id)
      setFirstDoc(snapshot.docs[0].data().updatedAt)
      setLastDoc(snapshot.docs[length - 1].data().updatedAt)
      setPrev(!initial && initialDoc !== snapshot.docs[0].id)
      setNext(length === pageSize)
    }
    setLoading(false)
  }

  const handleQuery = (prev = false, next = false) => {
    setLoading(true)
    setSelectedItems([])

    const first = prev ? firstDoc : false
    const last = next ? lastDoc : false

    let orderMappingRef = firebase.firestore().collection(`shops/${shop}/order-mappings`)
    if (queryValue && queryValue.length) {
      orderMappingRef = orderMappingRef.where("firstName", "in", [queryValue.toLowerCase(), queryValue.toUpperCase(), toTitleCase(queryValue)])
    }
    if (status && status.length) {
      orderMappingRef = orderMappingRef.where("status", "in", status)
    }
    if (orderAmount && orderAmount.length) {
      orderMappingRef = orderMappingRef.orderBy("total")
      const amounts = orderAmount[0].split(":")
      orderMappingRef = orderMappingRef
        .where("total", ">", amounts[0])
        .where("total", "<=", isNaN(amounts[1]) ? `${Number.MAX_SAFE_INTEGER}` : amounts[1])
    }
    orderMappingRef = orderMappingRef.orderBy("createdAt", "desc")
    if (first) {
      orderMappingRef = orderMappingRef.endBefore(first).limitToLast(pageSize)
    }
    if (last) {
      orderMappingRef = orderMappingRef.startAfter(last)
    }
    if (!first) {
      orderMappingRef = orderMappingRef.limit(pageSize)
    }
    orderMappingRef.get().then(snapshot => handleData(snapshot, !first && !last))
  }

  useEffect(() => {
    handleQuery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue, status, orderAmount])

  useEffect(() => {
    if (firstDoc) searchParams.set("first", btoa(firstDoc))
    if (lastDoc) searchParams.set("last", btoa(lastDoc))

    if (queryValue) {
      searchParams.set("search", queryValue)
    } else {
      searchParams.delete("search")
    }

    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue, firstDoc])

  const handleQueryChange = value => {
    setQueryValue(value)
    setSelectedItems([])
  }

  const handleClickPlan = (shopifyCustomerId, shopifyOrderId) => {
    navigate(`/payment/manage/${reverseShopifyAdminId(shopifyCustomerId)}/plan/${reverseShopifyAdminId(shopifyOrderId)}`)
  }

  const config = {
    ...genericNavBar,
    title: "Plans",
  }

  return (
    <Page title={config.title}>
      {location && <RoutePropagator location={location} />}
      <TitleBar {...config} />

      <PaymentPlan
        onClickPlan={handleClickPlan}
        status={status}
        setStatus={setStatus}
        orderAmount={orderAmount}
        setOrderAmount={setOrderAmount}
        items={items}
        queryValue={queryValue}
        onQueryChange={handleQueryChange}
        selectedItems={selectedItems}
        onSelectedChange={setSelectedItems}
        showPrev={showPrev}
        showNext={showNext}
        onPagination={handleQuery}
        loading={loading}
      />
    </Page>
  )
}

export default ReactifyApp.Firebase.withFirebase(ManagePaymentPlanPage)
