import React from "react"
import { ResourceItem, TextStyle, Badge } from "@shopify/polaris"

export const PaymentPlanItem = ({ item, onClickPaymentPlan }) => {
  const { firstName, lastName, total, status, shopifyCustomerId, shopifyOrderId, shopifyOrderName, email } = item

  const handleClick = () => {
    onClickPaymentPlan(shopifyCustomerId, shopifyOrderId)
  }

  const shortcutActions = [{ content: "View plan", onAction: handleClick }]
  const statusMappings = {
    Active: {
      status: "success",
      text: "Active",
    },
    Expired: {
      status: "complete",
      text: "Completed",
    },
    Canceled: {
      status: "incomplete",
      text: "Cancelled",
    },
  }

  const renderPlanBage = item => {
    return item.planId ? <Badge status={"info"}>{`payment-plan: ${item.planId}`}</Badge> : null
  }

  const renderStatusBage = item => {
    return item.status ? (
      <Badge status={statusMappings[status] ? statusMappings[status].status : "info"}>
        {statusMappings[status] ? statusMappings[status].text : status}
      </Badge>
    ) : null
  }

  return (
    <ResourceItem
      id={shopifyOrderId}
      onClick={handleClick}
      shortcutActions={shortcutActions}
      accessibilityLabel={`View details for ${shopifyOrderName}`}
      name={shopifyOrderName}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "55%",
          }}
        >
          <h3>
            <TextStyle variation="strong">{`${firstName} ${lastName} - (Order: ${shopifyOrderName})`}</TextStyle>
          </h3>
          <div>{email}</div>
        </div>
        <div
          style={{
            width: "15%",
            display: "flex",
          }}
        >
          {`$${total}`}
        </div>
        <div
          style={{
            width: "30%",
            display: "flex",
            justifyContent: "flex-end",
            textTransform: "capitalize",
          }}
        >
          {renderPlanBage(item)}
          {renderStatusBage(item)}
        </div>
      </div>
    </ResourceItem>
  )
}
