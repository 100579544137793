import React from "react"
import { Layout } from "@shopify/polaris"

import { PaymentPlanList } from "./PaymentPlanList"
import { PaymentPlanPagination } from "./PaymentPlanPagination"

export const PaymentPlan = props => {
  const { items, showPrev, showNext, onPagination, loading } = props

  return (
    <Layout>
      <Layout.Section>
        <PaymentPlanList {...props} />
        <br />
        <PaymentPlanPagination
          showPrev={showPrev && !loading && items.length}
          showNext={showNext && !loading && items.length}
          onPagination={onPagination}
        />
      </Layout.Section>
    </Layout>
  )
}
