import React, { useCallback } from "react"
import { ChoiceList, Filters } from "@shopify/polaris"

export const PaymentPlansFilter = ({ status, setStatus, orderAmount, setOrderAmount, queryValue, setQueryValue }) => {
  const handleOrderAmountChange = useCallback(value => setOrderAmount(value), [setOrderAmount])
  const handleFiltersQueryChange = useCallback(value => setQueryValue(value), [setQueryValue])
  const handleStatusRemove = useCallback(() => setStatus([]), [setStatus])
  const handleOrderAmountRemove = useCallback(() => setOrderAmount([]), [setOrderAmount])
  const handleQueryValueRemove = useCallback(() => setQueryValue(false), [setQueryValue])
  const handleFiltersClearAll = useCallback(() => {
    handleStatusRemove()
    handleOrderAmountRemove()
    handleQueryValueRemove()
  }, [handleStatusRemove, handleQueryValueRemove, handleOrderAmountRemove])

  const filters = [
    {
      key: "orderAmount",
      label: "Order amount",
      filter: (
        <ChoiceList
          title="Order amount"
          titleHidden
          choices={[
            { label: "<= $3000", value: "0:3000" },
            { label: "$3000 ~ $3500", value: "3500:4000" },
            { label: "$4000 ~ $4500", value: "4000:4500" },
            { label: "$4500 ~ $5000", value: "4500:5000" },
            { label: "> $5000", value: "5000:max" },
          ]}
          selected={orderAmount || []}
          onChange={handleOrderAmountChange}
          allowMultiple={false}
        />
      ),
      shortcut: true,
    },
  ]

  const appliedFilters = []
  if (!isEmpty(status)) {
    const key = "status"
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, status),
      onRemove: handleStatusRemove,
    })
  }
  if (!isEmpty(orderAmount)) {
    const key = "orderAmount"
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, orderAmount),
      onRemove: handleOrderAmountRemove,
    })
  }

  function disambiguateLabel(key, value) {
    switch (key) {
      case "status":
        return value.map(val => `Status on ${val}`).join(", ")
      case "orderAmount":
        return value.join(", ")
      default:
        return value
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0
    } else {
      return value === "" || value == null
    }
  }

  return (
    <Filters
      queryValue={queryValue}
      filters={filters}
      appliedFilters={appliedFilters}
      onQueryChange={handleFiltersQueryChange}
      onQueryClear={handleQueryValueRemove}
      onClearAll={handleFiltersClearAll}
    />
  )
}
